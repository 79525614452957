import React, { useEffect, useState } from "react";
import Navbar from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";

const Profile = (props) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    console.log("get user");
    fetch("http://localhost:8000/user")
      .then((response) => response.json())
      .then((result) => {
        setUser(result?.user);
        console.log("result: ", result);
      })
      .catch((err) => console.error("Some Err: ", err));
  }, []);
  return (
    <div className="container">
      <div className="profile profile-dark pt-3 px-4">
        <Navbar user={user} />
        <div className="profile-content">
          <div className="profile-img"></div>
          <div className="profile-text col-md-5 col-sm-12">
            <h3 className="text-light">{user?.title ?? "Web Developer"}</h3>
            <h2 className="text-light">{user?.name ?? "Marc Sitze"}</h2>
            <p className="text-light">
              {user?.description ??
                "Hey I'm a tech guy who loves solving problemes"}
            </p>
            <div className="profile-buttons">
              <a
                className="btn btn-cv btn-cv-light"
                rel="noreferrer"
                target="_blank"
                href={user?.cvlink ?? "#"}
              >
                Download CV
              </a>
              <a
                className="btn btn-contact btn-contact-light"
                rel="noreferrer"
                href="#"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
        <div className="about mx-4">
          <h3 className="about-title">What I Do</h3>
          <div className="about-boxes">
            <div className="row about-row d-flex justify-content-between mx-2">
              {user?.services?.map((service, index) =>
                <div
                  key={index}
                  className="about-box px-0 mb-3 col-md-6 col-12"
                >
                  <i className="fa fa-star fa-2x text-main mr-4"></i>
                  <div className="about-text">
                    <h3 className="text-light">{service?.title}</h3>
                    <p className="text-light">{service?.description}</p>
                  </div>
                </div>
              )}
              <div className="about-box px-0 mb-3 col-md-6">
                <i className="fa fa-star fa-2x text-main mr-4"></i>
                <div className="about-text">
                  <h3 className="text-light">Front-end web</h3>
                  <p className="text-light">
                    I create the technical features for a website's look. I
                    develop the site's layout and integrate graphics,
                    applications (such as a retail checkout tool), and other
                    content. I also write webdesign programs in a variety of
                    computer languages, such as HTML or JavaScript.
                  </p>
                </div>
              </div>
              <div className="about-box px-0 mb-3 col-md-6">
                <i className="fa fa-star fa-2x text-main mr-4"></i>
                <div className="about-text">
                  <h3 className="text-light">Web interface wireframing</h3>
                  <p className="text-light">
                    I create the look of a website or interface with regard to
                    photos, color, font type and size, graphics, and layout.
                    They also are responsible for the functionality, usability,
                    and compatibility of the website or interface..
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer user={user} />
      </div>
    </div>
  );
};

export default Profile;
