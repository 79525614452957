import React from "react";

function Footer() {
  const date = new Date().getFullYear();
  return (
    <div>
      <hr className="hr-separator" />
      <div className="container d-flex pr-0 footer-container justify-content-between">
        <div>
          <a
            className="mr-2 text-gray"
            target="_blank"
            rel="noreferrer"
            href="https://github.com/marcSitze"
          >
            <i className="fab fa-github text-light"></i>
          </a>
          <a
            className="mx-2 text-gray"
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.cn/in/the-black-dev-19816419a"
          >
            <i className="fab fa-linkedin-in text-light"></i>
          </a>
          <a
            className="mx-2 text-gray"
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/Marcjorel1"
          >
            <i className="fab fa-twitter text-light"></i>
          </a>
          <a className="mx-2 text-gray" href="https://wa.me/699576276">
            <i className="fab fa-whatsapp text-light"></i>
          </a>
          <a
            className="mx-2 text-gray"
            target="_blank"
            rel="noreferrer"
            href="https://facebook.com/marcsitze"
          >
            <i className="fab fa-facebook text-light"></i>
          </a>
          <a
            className="mx-2 text-gray"
            target="_blank"
            rel="noreferrer"
            href="https://instagram.com/jorelmarc"
          >
            <i className="fab fa-instagram text-light"></i>
          </a>
          <a
            className="mx-2 text-gray"
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCvfgOMn6WQOiMfBDHGZT5mA"
          >
            <i className="fab fa-youtube text-light"></i>
          </a>
        </div>
        <p className="text-light">
          &copy; {date} All rights reserved.{" "}
          <span className="text-main">@theblackdev</span>.
        </p>
      </div>
    </div>
  );
}

export default Footer;
