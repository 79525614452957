import React, { useState, useEffect} from "react";
import Navbar from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import config from "../config";

const Resume = (props) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    console.log('get user');
    fetch(config.API + '/user')
    .then(response => response.json())
    .then(result => {
      setUser(result?.user);
      console.log('result: ', result);
    })
    .catch(err => console.error('Some Err: ', err))
  }, []);
  return (
    <div className="container">
      <div className="profile profile-dark pt-3 px-4">
        <Navbar user={user} />
        <div className="resume-header mx-n4">
          <h2 className="text-light">Resume</h2>
        </div>
        <div className="educ-container">
          <div className="row mb-5">
            {/* <div className="col-6">
              <h3 className="text-light educ-title">Education</h3>
            </div>
            <div className="col-6">
              <h3 className="text-light educ-title">Experience</h3>
            </div> */}
            <div className="col-md-6">
            <h3 className="text-light educ-title">Education</h3>
              {user?.resume?.map((data, index) =>
              <>
              {data?.mainType === 'EDUCATION' && <div key={index} className="text-light educ-box">
              <div className="educ-header text-light">
                <span className="educ-date">{data?.yearStart.split('-')[0]} - {data?.yearEnd.split('-')[0]}</span>
                <span className="educ-header-text">University of Yde1</span>
              </div>
              <h4>UI Design</h4>
              <p>
                Duis posuere, quam non imperdiet egestas, eros enim mattis
                mauris, in posuere lacus arcu quis felis. Etiam interdum erat
                non enim venenatis fermentum.{" "}
              </p>
            </div>}
              </>
              )}

            </div>
            <div className="col-md-6">
            <h3 className="text-light educ-title">Experience</h3>
            {user?.resume?.map((data, index) => 
            <>
            {data?.mainType === 'EXPERIENCE' && <div key={index} className="text-light educ-box">
                <div className="educ-header text-light">
                  <span className="educ-date">2018</span>
                  <span className="educ-header-text">University of Yde1</span>
                </div>
                <h4>UI Design</h4>
                <p>
                  Duis posuere, quam non imperdiet egestas, eros enim mattis
                  mauris, in posuere lacus arcu quis felis. Etiam interdum erat
                  non enim venenatis fermentum.{" "}
                </p>
              </div>}
              </>
            )}
            </div>
          </div>
              <h3 className="text-light certificates-title">Certificates</h3>
            <div className="row certificates">
              <div className="col-md-6 px-0">
                <div className="text-light certificate-box">
                  <div className="certificate-bg">Mern</div>
                  <div className="certificates-text">
                    <h4>Udemy MERN stack developpement</h4>
                    <h5>19 April 2020</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="row skills">
              <div className="col-md-6">
            <h3 className="text-light skills-title">Skills</h3>
            {user?.skills?.split(',').map((skill, index) => <span key={index} class="badge badge-pill badge-primary mr-1">{skill}</span>)}
              {/* <span class="badge badge-pill badge-primary mr-1">html</span>
              <span class="badge badge-pill badge-primary mr-1">css</span>
              <span class="badge badge-pill badge-primary mr-1">javascript</span>
              <span class="badge badge-pill badge-primary mr-1">bootstrap</span>
              <span class="badge badge-pill badge-primary mr-1">jquery</span>
              <span class="badge badge-pill badge-primary mr-1">react</span>
              <span class="badge badge-pill badge-primary mr-1">react-native</span>
              <span class="badge badge-pill badge-primary mr-1">redux</span>
              <span class="badge badge-pill badge-primary mr-1">nuxt/vuejs</span>
              <span class="badge badge-pill badge-primary mr-1">vuex</span>
              <span class="badge badge-pill badge-primary mr-1">nodejs</span>
              <span class="badge badge-pill badge-primary mr-1">expressjs</span>
              <span class="badge badge-pill badge-primary mr-1">nestjs</span>
              <span class="badge badge-pill badge-primary mr-1">mongodb</span>
              <span class="badge badge-pill badge-primary mr-1">firebase</span>
              <span class="badge badge-pill badge-primary mr-1">json</span> */}
              </div>
              <div className="col-md-6">
                <h3 className="text-light skills-title">Other Skills</h3>
                {user?.otherSkills?.split(',').map((skill, index) => <span key={index} class="badge badge-pill badge-secondary mr-1">{skill}</span>)}
                {/* <span class="badge badge-pill badge-secondary mr-1">jest</span>
                <span class="badge badge-pill badge-secondary mr-1">mysql</span>
                <span class="badge badge-pill badge-secondary mr-1">heroku</span>
                <span class="badge badge-pill badge-secondary mr-1">strapi</span>
                <span class="badge badge-pill badge-secondary mr-1">java</span>
                <span class="badge badge-pill badge-secondary mr-1">python</span>
                <span class="badge badge-pill badge-secondary mr-1">c/c++</span> */}
              </div>
            </div>
        </div>
        <Footer user={user} />
      </div>
    </div>
  );
};

export default Resume;
